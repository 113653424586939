.auth-modal {
	color: $color-dark-gray;
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	z-index: 200;
	background: rgba(0,0,0,0.5);
	display: flex;
	align-items: center;
	justify-content: center;

	.inside {
		background: #fff;
		width: 911px;
		height: 720px;
		box-shadow: 0px 10px 14px rgba(0, 0, 0, 0.5);
		border-radius: 15px;
		box-sizing: border-box;
		padding: 40px;
		display: flex;
		flex-direction: column; }

	header {
		padding-bottom: 20px;
		display: flex;
		justify-content: space-between;
		align-items: flex-start;

		.tabs {
			@include reset-list;
			display: flex;
			font-size: 20px;
			line-height: 1.2;
			font-weight: 600;
			color: $color-light-gray;

			li {
				margin-left: 60px;
				padding-bottom: 10px;
				border-bottom: 2px solid transparent;
				cursor: pointer;

				&.active {
					font-weight: 700;
					color: $color-dark-gray;
					border-bottom-color: $color-dark-gray; } } }

		> span {
			cursor: pointer; } }

	.contents {
		flex-grow: 1;
		display: flex;
		align-items: center;
		width: 680px;
		margin: 0 auto;

		.tab-contents {
			display: none;
			width: 100%;

			&.active {
				display: block; } } }

	.cols {
		justify-content: center;

		> div {
			+ div {
				margin-left: 4%; } } }

	figure {
		margin: 0 0 30px;

		img {
			width: 100%; } }

	.form-section {
		margin-bottom: 0; }

	.signup-cta {
		font-size: 15px;
		font-weight: 700;
		margin: 0 0 25px;
		line-height: 1.2; }

	.dotted-list {
		list-style-type: none;
		padding: 0 0 0 20px;
		margin: 0;
		font-size: 15px;

		li {
			margin: 0 0 12px;

			&:last-child {
				margin-bottom: 0; }

			svg {
				vertical-align: middle;
				margin-right: 12px; } } }

	.tab-title {
		margin: 0 0 28px;
		font-weight: 600;
		font-size: 22px;
		line-height: 26px;

		&.mb-s {
			margin-bottom: 10px; }

		strong {
			font-weight: 700; } }

	.tab-info {
		margin-bottom: 25px;
		font-size: 14px;
		line-height: 1.4; }

	.or {
		text-align: center;
		margin: 12px 0;
		font-size: 17px;
		line-height: 1.2;
		color: #797979;
		position: relative;

		&:before {
			content: '';
			height: 1px;
			position: absolute;
			left: 0;
			right: 0;
			top: 50%;
			background: $color-border;
			z-index: 2; }

		span {
			background: #fff;
			display: inline-block;
			padding: 0 10px;
			position: relative;
			z-index: 3; } }

	.form-buttons {
		margin-top: 25px; }

	.sp-button {
		width: 100%;
		height: 46px;
		font-weight: 700;
		text-transform: uppercase;

		&.linkedin {
			background: #00455B;

			svg {
				vertical-align: -3px;
				margin-right: 8px; } } }

	.extra-options {
		display: flex;
		font-size: 12px;
		align-items: center;
		justify-content: space-between; }

	.terms {
		font-size: 12px;
		margin-top: 10px;

		a {
			color: $color-light-blue;
			text-decoration: none; } } }
