@media (max-width: 1029px) {
	.site-content {
		max-width: none;
		margin: 0 20px; }

	.modal.modal-upload {
		margin-left: -245px;
		width: 490px; }

	.photo-grid {
		width: 680px;

		//&.photo-grid-4, &.photo-grid-5, &.photo-grid-6, &.photo-grid-7
		&.photo-grid-0, &.photo-grid-1, &.photo-grid-2, &.photo-grid-3 {
			margin-left: -340px; }

		.tile {
			.tools {
				display: block;
				top: auto;
				height: auto;
				bottom: 24px;
				overflow: hidden;
				padding: 5px;
				box-sizing: border-box;

				button {
					position: static !important;
					float: left;
					margin: 0 !important;

					&.edit {
						height: 32px !important; }

					&.delete {
						float: right;
						margin-top: 4px !important; } } } } }

	.global-modal .outer {
		margin-left: -330px;
		width: 660px; }

	.global-modal-checkout .outer {
		margin-left: -245px;
		width: 490px; } }
