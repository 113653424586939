@media (max-width: 767px) {
	.form .formfield {
		input, select {
			height: 40px; } }

	.header {
		.order-bar {
			.order-info {
				float: none; }
			.buttons {
				background: #fff;
				box-shadow: 0 0 8px #777;
				position: fixed;
				padding: 15px;
				left: 0;
				bottom: 0;
				right: 0;
				float: none;
				overflow: hidden;
				.action-button {
					float: left;
					margin: 0 2% 0 0;
					box-sizing: border-box;
					padding: 10px 0;
					text-align: center;
					width: 49%;
					z-index: 20;
					&:last-child {
						margin-right: 0; } } } }
		.menu .dropdown {
			padding: 25px 0 10px 25px;
			width: 270px; } }
	.footer {
		display: none; }

	.modal.modal-start {
		margin-left: -160px;
		width: 320px;
		padding: 30px 15px; }

	.modal.modal-upload {
		margin-left: -160px;
		width: 320px;
		padding: 90px 0;
		height: auto;

		h3, .or {
			display: none; } }

	.photo-grid {
		margin: 0 auto !important;
		padding: 60px 0 60px 10px;
		position: relative !important;
		left: auto !important;
		top: auto !important;
		width: 320px; }

	.low-resolution-warning {
		margin-left: -160px;
		padding: 30px 20px;
		width: 320px;

		.title {
			font-size: 22px; } }

	.content-modal {
		.photo-grid {
			display: none; } }

	.global-modal {
		.outer, .outer.outer-info, .outer.outer-thanks {
			margin-left: -160px;
			margin-top: -234px;
			width: 320px; }

		.inner.inner-crop .cropper-container {
			height: 296px; }

		.inner.inner-info {
			.big-title {
				font-size: 32px; }
			.middle-title, .medium-title, .close-button {
				font-size: 16px; }
			ul {
				font-size: 14px;
				margin-bottom: 40px;
				li {
					margin-bottom: 0; }
				img {
					height: 10px; } } }

		.inner.inner-thanks {
			figure {
				img {
					width: 80px; } }
			.big-title {
				font-size: 32px;
				margin-bottom: 20px; }
			p {
				margin: 0 20px 20px;
				br {
					display: none; } } } }

	.global-modal.global-modal-checkout {
		.outer {
			margin-top: -290px;

			.inner {
				padding: 11px 10px;

				.title {
					margin-bottom: 15px;
					&.has-subtitle {
						margin-bottom: 0; } }
				dl {
					margin: 20px;
					width: auto; }
				figure {
					margin: 15px 0; } } } }

	.loading-panel, .upload-failed {
		.loading-panel-inside, .upload-failed-inside {
			margin-left: -150px;
			padding: 50px 0;
			width: 300px;

			.subtitle {
				margin-bottom: 0; }
			.loading-bar, .info {
				margin: 40px auto 0;
				width: 240px; } }

		.upload-failed-inside {
			.title {
				font-size: 22px; }
			.subtitle {
				font-size: 18px;
				margin-bottom: 30px; }
			.info {
				margin-top: 0; } } } }
