$color-pink: #e76176;
$color-green: #87cdc3;
$color-gray: #a5a5a5;

body {
	position: relative;
	margin: 0;
	line-height: 1.5;
	padding: 0;
	background: #e8e8e8;
	letter-spacing: 1px; }

body, input, textarea, select, button {
	font-family: 'Prompt', sans-serif; }

input[type=file] {
	display: none; }

img {
	vertical-align: middle; }

.action-button {
	background: $color-pink;
	padding: 15px 25px;
	font-weight: 700;
	text-decoration: none;
	color: #fff;
	border-radius: 5px;
	display: inline-block;
	border: 0 none;
	font-size: 16px;
	letter-spacing: 1px;
	cursor: pointer;
	outline: none;

	&:disabled {
		opacity: 0.5;
		cursor: default; }

	&.green {
		background: $color-green;
		&:hover {
			background: #44cdc3; } }
	&.white {
		background: #fff;
		color: #555; }
	&.inverted {
		border: 2px solid $color-pink;
		padding: 13px 23px;
		background: #fff;
		color: $color-pink; }
	&.size-x {
		font-size: 13px;
		padding: 10px 50px;
		&.inverted {
			padding: 8px 50px; } }
	img {
		margin-left: 10px;
		vertical-align: -3px; } }

.loading-wheel, .loading-panel, .upload-failed {
	background: rgba(0,0,0,0.55);
	position: fixed !important;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 150 !important;

	.loading-wheel-inside {
		background: #fff;
		border-radius: 5px;
		box-shadow: 0 0 5px #555;
		position: fixed !important;
		left: 50%;
		top: 50%;
		margin-left: -52px;
		margin-top: -52px;
		z-index: 200;
		padding: 20px;

		img {
			margin: 0 !important; } }

	.loading-panel-inside {
		background: #fff;
		border-radius: 5px;
		box-shadow: 0 0 5px #555;
		position: fixed !important;
		left: 50%;
		top: 50%;
		margin-left: -250px;
		margin-top: -52px;
		z-index: 200;
		padding: 70px 0 80px;
		width: 500px;
		box-sizing: border-box;
		margin-top: -224px;
		text-align: center;

		.title {
			color: $color-green;
			line-height: 1.2;
			font-size: 36px;
			font-weight: 700;
			margin-bottom: 18px; }

		.subtitle {
			color: $color-green;
			margin-bottom: 60px;
			line-height: 1.2;
			font-size: 24px;
			font-weight: 500; }

		.loading-bar {
			position: relative;
			height: 23px;
			background: #ebebeb;
			margin: 0 auto 55px;
			width: 370px;

			.loading-bar-progress {
				transition: all 200ms linear;
				position: absolute;
				left: 0;
				top: 0;
				height: 100%;
				background: $color-pink;
				width: 0%; } }

		.info {
			margin: 0 auto;
			width: 370px;
			letter-spacing: normal;
			color: $color_gray; } }

	.upload-failed-inside {
		background: #fff;
		border-radius: 5px;
		box-shadow: 0 0 5px #555;
		position: fixed !important;
		left: 50%;
		top: 50%;
		margin-left: -250px;
		margin-top: -52px;
		z-index: 200;
		padding: 70px 0 80px;
		width: 500px;
		box-sizing: border-box;
		margin-top: -224px;
		text-align: center;

		.title {
			color: $color-green;
			line-height: 1.2;
			font-size: 36px;
			font-weight: 500;
			margin-bottom: 10px; }

		.subtitle {
			color: $color-green;
			margin-bottom: 30px;
			line-height: 1.2;
			font-size: 24px;
			font-weight: 500; }

		.action-button {
			margin-bottom: 40px;
			width: 200px; }

		.info {
			margin: 0 auto;
			width: 370px;
			letter-spacing: normal;
			color: $color_gray; } } }

#root, .app {
	position: relative; }

.content {
	padding-top: 70px;
	padding-bottom: 30px;
	min-height: 100vh;
	position: relative;
	z-index: 5;
	box-sizing: border-box;

	&.content-modal {
		z-index: 15; }

	&.image-bkg {
		background: url(../img/bkg.jpg) center center no-repeat / cover; }

	&:after {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		content: "";
		background: rgba(255,255,255,0.5);
		z-index: 3; }

	> * {
		position: relative;
		z-index: 10; } }

.header {
	position: absolute;
	left: 0;
	right: 0;
	background: #fff;
	top: 0;
	box-shadow: 0 0 8px #777;
	z-index: 10;

	&.menu-opened {
		z-index: 11; }

	.top-part {
		padding: 12px 0; }

	.logo {
		float: left; }
	.menu-overlay {
		position: fixed;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		background: rgba(0,0,0,0.55);
		z-index: 20; }
	.menu {
		float: right;
		position: relative;
		z-index: 25;

		.trigger {
			cursor: pointer;
			margin-top: 12px;

			span {
				background: $color-pink;
				display: block;
				width: 28px;
				height: 4px;
				border-radius: 2px;
				margin-bottom: 4px;

				&:last-child {
					margin-bottom: 0; } } }

		.dropdown {
			background: #fff;
			padding: 35px 0 10px 35px;
			width: 310px;
			position: absolute;
			right: 0;
			top: 0;

			.close {
				position: absolute;
				right: 12px;
				top: 10px;
				cursor: pointer; }

			ul {
				padding: 0;
				margin: 0;
				list-style-type: none;

				li {
					margin-bottom: 20px;

					span, a {
						display: inline-block;
						cursor: pointer;
						text-decoration: none;
						color: inherit; } } } } }

	.order-bar {
		background: #f8f7f7;
		padding: 15px 0;
		display: none;

		.order-info {
			float: left;
			line-height: 1.4;
			font-size: 16px;
			color: #6d6d6d;
			font-weight: 300;

			.line1 {
				font-weight: 500;

				img {
					cursor: pointer;
					margin-left: 5px;
					vertical-align: -3px; } }

			.line2 {
				font-size: 13px;
				color: $color-gray;
				letter-spacing: normal; } }

		.buttons {
			float: right;

			.action-button {
				margin-left: 10px; } } } }


.footer {
	position: absolute;
	left: 0;
	right: 0;
	background: #fff;
	bottom: 0;
	box-shadow: 0 0 8px #777;
	box-sizing: border-box;
	padding-right: 20px;
	z-index: 10;
	height: 40px;
	font-size: 12px;
	text-align: right;
	line-height: 40px;
	color: rgba(0,0,0,0.5); }

.site-content {
	margin: 0 auto;
	max-width: 1000px; }

.modal {
	position: absolute;
	left: 50%;
	top: 50%;
	z-index: 10;

	&.modal-start {
		background: #fff;
		margin: 0 auto;
		width: 440px;
		margin-left: -220px;
		margin-top: -152px;
		border-radius: 8px;
		box-shadow: 0 0 5px #777;
		box-sizing: border-box;
		padding: 35px 40px;

		h3 {
			text-align: center;
			color: #565656;
			font-weight: 700;
			margin: 0 0 20px;
			text-transform: uppercase; } }

	&.modal-upload {
		background: rgba(255,255,255,0.75);
		border: 3px dashed #a1a1a1;
		box-sizing: border-box;
		border-radius: 8px;
		margin-left: -330px;
		margin-top: -165px;
		width: 660px;
		height: 360px;
		padding-top: 55px;
		text-align: center;

		.drag-mask {
			border: dashed grey 4px;
			background: rgba(255,255,255,0.8);
			position: absolute;
			top: -4px;
			bottom: -4px;
			left: -4px;
			right: -4px;
			border-radius: 5px;
			z-index: 9999; }

		img {
			display: block;
			margin: 0 auto 10px; }
		h3 {
			color: #787878;
			margin: 0 0 10px;
			font-size: 24px;
			font-weight: 700; }
		.or {
			font-weight: 200;
			color: #b0b0b0;
			margin: 0 0 15px;
			font-size: 19px; } } }

.form {
	.formfield {
		margin-bottom: 4px;
		overflow: hidden;

		.col {
			float: left;
			width: 49%;
			margin-right: 2%;
			&:last-child {
				margin-right: 0; } }

		input, select {
			width: 100%;
			background: #f6f5f5;
			border: 0 none;
			height: 48px;
			padding: 0 15px;
			box-sizing: border-box;
			outline: none;
			font-size: 16px;

			&.error {
				border: 1px solid $color-pink; } }

		.error-msg {
			color: #f09f5d;
			padding-top: 2px;
			text-align: left;
			font-size: 12px;
			font-weight: 300; }

		.fake-button {
			display: block;
			text-align: left;
			line-height: 48px;
			padding: 0 5px;
			font-size: 0.9em;
			opacity: 0.6;
			text-decoration: underline;
			cursor: pointer; } }

	.submit {
		text-align: center;
		margin-top: 25px; } }

/* Photos */
body.photos {
	.header {
		.order-bar {
			display: block; } }
	.content {
		padding-top: 140px; } }

.photo-grid {
	text-align: center;
	width: 884px;
	font-size: 0;
	margin: 30px auto;

	//, &.photo-grid-4, &.photo-grid-5, &.photo-grid-6, &.photo-grid-7
	&.photo-grid-0, &.photo-grid-1, &.photo-grid-2, &.photo-grid-3 {
		position: absolute;
		left: 50%;
		margin-left: -442px;
		top: 50%;
		margin-top: -112px; }

	// &.photo-grid-4, &.photo-grid-5, &.photo-grid-6, &.photo-grid-7
	/// margin-top: -225px

	.tile {
		background: url(../img/frame-3.png) left top no-repeat;
		box-sizing: border-box;
		height: 225px;
		width: 221px;
		display: inline-block;
		text-align: left;
		padding: 5px 0 0 5px;
		vertical-align: top;
		position: relative;

		&:hover {
			.tools {
				display: block; } }

		> img {
			width: 196px; }

		.loading-wheel {
			background: #fff;
			position: relative !important;
			width: 196px;
			height: 196px;
			left: auto;
			right: auto;
			top: auto;
			bottom: auto;

			.loading-wheel-inside {
				position: absolute !important;
				background: transparent;
				box-shadow: none; }

			.loading-progress-bar {
				position: absolute;
				left: 50%;
				top: 50%;
				height: 10px;
				margin-top: -5px;
				width: 110px;
				margin-left: -55px;
				background: #dddddd;

				.progress-bar {
					background: #89cdc3;
					position: absolute;
					left: 0;
					height: 100%;
					animation-name: grow;
					animation-duration: 1.5s;
					animation-iteration-count: 1;
					animation-fill-mode: forwards; } } }

		.photo {
			background-size: cover;
			background-position: center center;
			background-repeat: no-repeat;
			width: 196px;
			height: 196px;

			img {
				width: 100%; } }

		.tools {
			background: rgba(44,48,61,0.6);
			position: absolute;
			left: 5px;
			top: 5px;
			display: none;
			height: 196px;
			width: 196px;
			z-index: 5;

			.edit {
				background: rgba(255, 255, 255, 0.4);
				cursor: pointer;
				font-size: 20px;
				color: #fff;
				height: 48px;
				width: 110px;
				position: absolute;
				left: 50%;
				top: 50%;
				margin-left: -55px;
				margin-top: -24px;
				outline: none;
				padding: 0;
				border: 0 none;
				border-radius: 24px;
				text-transform: uppercase; }

			.delete {
				position: absolute;
				outline: none;
				cursor: pointer;
				right: 8px;
				top: 15px;
				background: url(../img/icon-trash.png) left top no-repeat;
				border: 0;
				display: block;
				text-indent: -5000px;
				width: 24px;
				height: 24px; } } }

	.add-more {
		background: url(../img/bkg-add-more.png) 5px 5px no-repeat;
		display: inline-block;
		height: 225px;
		width: 221px;
		cursor: pointer; } }

.low-resolution-warning {
	position: absolute;
	background: #fff;
	left: 50%;
	top: 50%;
	margin-left: -200px;
	margin-top: -250px;
	padding: 35px 40px 30px;
	box-sizing: border-box;
	border-radius: 10px;
	text-align: center;
	width: 400px;

	.title {
		margin: 0 0 30px;
		text-transform: uppercase;
		font-weight: 500;
		line-height: 1;
		font-size: 26px;
		opacity: 0.6; }

	figure {
		margin: 0 auto 20px;
		width: 180px;

		img {
			width: 100%; } }

	.brief {
		margin: 0 0 20px;
		color: $color-gray;
		font-size: 15px;
		letter-spacing: normal; }

	.action-button {
		display: block;
		margin: 0 0 10px;
		text-transform: uppercase;
		font-weight: 500;
		width: 100%;

		&:last-child {
			margin-bottom: 0; } } }

/* Checkout */
body.checkout {
	.order-bar {
		display: block; }
	.content {
		padding-top: 140px; } }

.global-modal {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 100;
	background: rgba(#010101, 0.5);

	.outer {
		background: #fff;
		position: absolute;
		left: 50%;
		top: 50%;
		width: 700px;
		margin-left: -350px;
		margin-top: -330px;
		border-radius: 8px;
		box-shadow: 0 0 8px #555;

		&.outer-info {
			width: 500px;
			margin-left: -250px; }

		&.outer-thanks {
			width: 480px;
			margin-left: -240px; }

		.close {
			position: absolute;
			left: 10px;
			top: 10px;
			width: 30px;
			line-height: 30px;
			text-align: center;
			cursor: pointer; } }

	@media (max-height: 690px) {
		position: absolute;
		padding: 20px 0;
		overflow: auto;

		.outer {
			position: relative;
			left: auto;
			top: auto;
			margin: 0 auto !important; } }

	.inner {
		margin: 0 auto;
		padding: 30px 0 20px;
		max-width: 360px;
		text-align: center;

		&.inner-crop {
			max-width: 520px;
			padding: 20px 0;
			margin: 0 auto;

			.cropper-container {
				position: relative;
				height: 496px;
				border: 12px solid #EBEDEF;
				margin-bottom: 20px;
				.cropper-area {
					border: 3px solid #fff;
					&:before, &:after {
						content: none; } } } }

		&.inner-info {
			padding: 20px 20px 40px;
			color: #565656;
			max-width: none;

			.big-title {
				color: $color_pink;
				margin: 40px 0 10px;
				line-height: 1.1;
				font-weight: 500;
				font-size: 44px; }
			.middle-title {
				margin-bottom: 7px;
				line-height: 1.1;
				font-weight: 500;
				font-size: 24px; }
			.medium-title {
				color: $color_pink;
				text-transform: uppercase;
				margin: 50px 0 15px;
				line-height: 1.1;
				font-weight: 500;
				font-size: 24px; }
			ul {
				list-style-type: none;
				padding: 0;
				margin: 0 0 60px;
				font-weight: 300;
				font-size: 18px;

				li {
					margin-bottom: 10px; }
				img {
					height: 20px;
					margin: 0 7px 0 0;
					vertical-align: middle; } } }

		&.inner-thanks {
			padding: 40px 20px 30px;
			color: #565656;
			max-width: none;

			figure {
				margin: 0 0 20px; }

			.big-title {
				color: $color_green;
				margin: 0 0 30px;
				line-height: 1;
				font-weight: 500;
				font-size: 44px; }

			p {
				color: $color-gray;
				letter-spacing: normal;
				margin: 0 0 30px;

				a {
					color: $color-green;
					text-decoration: none; } }

			.big-title {
				color: $color-green; } }

		.close-button {
			display: inline-block;
			font-weight: 500;
			color: #565656;
			text-transform: uppercase;
			cursor: pointer;
			font-size: 24px;
			text-decoration: none; }

		.title {
			margin: 0 0 30px;
			font-size: 20px;
			color: #565656;
			text-transform: uppercase;
			font-weight: 500;
			line-height: 1.2;
			&.has-subtitle {
				margin-bottom: 0; } }
		.subtitle {
			font-size: 13px;
			line-height: 1.2;
			color: #565656;
			opacity: 0.5;
			margin-bottom: 10px; }
		dl {
			overflow: hidden;
			margin: 20px auto;
			width: 320px;
			font-size: 16px;
			color: #565656;
			dt {
				clear: both;
				float: left;
				margin: 0;
				text-align: left; }
			dd {
				float: right;
				margin: 0;
				text-align: right; }
			> *.total {
				border-top: 1px solid #d6d4d4;
				margin-top: 3px;
				padding-top: 3px;
				font-weight: 500;
				width: 50%; } }
		figure {
			margin: 20px 0 0; } } }

.ios-redirect {
	background: #fff;
	position: fixed;
	z-index: 20000;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	text-align: center;

	.inside-wrapper {
		position: absolute;
		left: 50%;
		top: 50%;
		width: 260px;
		line-height: 1.4;
		font-weight: 300;
		font-size: 14px;
		margin-left: -130px;
		margin-top: -192px;
		letter-spacing: normal;
		color: $color-gray;

		figure {
			margin: 0 0 20px;
			img {
				vertical-align: middle; } }
		h3 {
			text-transform: uppercase;
			margin: 0;
			font-weight: 500;
			line-height: 1;
			margin: 0 0 20px;
			font-size: 22px;
			color: #555; }
		p {
			margin: 0 0 20px; }
		.action-button {
			margin: 10px 0 0;
			display: block; } } }

.clearfix {
  &:after {
    content: "";
    display: table;
    clear: both; } }

@keyframes grow {
  from {
    width: 0; }
  to {
    width: 100%; } }
