@import url('https://fonts.googleapis.com/css2?family=Lato:wght@700&family=Libre+Baskerville&family=Open+Sans+Condensed:wght@700&display=swap');

$color-border: #DCDEDE;
$color-light-blue: #009ACB;
$color-dark-blue: #006E90;
$color-dark-gray: #434343;
$color-gray: #868686;
$color-light-gray: #BBBBBB;
$color-light-gray2: #C4C4C4;
$color-red: #ff0000;
$color-secondary: #808080;
$bkg-color-light-gray: #F3F5F4;

@mixin reset-list {
	margin: 0;
	padding: 0;
	list-style: none; }

@mixin reset-figure {
	margin: 0;
	padding: 0;
	list-style: none; }

svg {
	vertical-align: middle; }

canvas {
	font-smooth: never;
	-webkit-font-smoothing: none; }

.title-line {
	display: flex;
	justify-content: space-between;
	align-items: flex-start; }

.panel-title {
	font-weight: 600;
	font-size: 13px;
	line-height: 1.3;
	letter-spacing: -0.03em;
	color: $color-dark-gray;
	margin: 0 0 15px; }

.section-title {
	font-weight: 600;
	font-size: 20px;
	line-height: 1.2;
	color: $color-dark-gray;
	margin-bottom: 32px;
	text-transform: uppercase; }

.title-bar {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 32px;

	.section-title {
		margin-bottom: 0; }
	.text-button {
		font-size: 16px; }
	.title-bar-buttons {
		> * {
			margin-left: 8px;
			vertical-align: middle; } } }

.section-subtitle {
	font-weight: 700;
	font-size: 15px;
	line-height: 1.333;
	text-transform: uppercase;
	margin: 35px 0 20px; }

.subtitle-bar {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 20px;

	.section-subtitle {
		margin: 0; }
	.text-button {
		font-size: 12px; } }

.section-info {
	font-size: 15px;
	line-height: 1.333;
	color: $color-dark-gray; }

.field-label {
	font-weight: 500;
	display: block;
	font-size: 11px;
	line-height: 1.18;
	margin-bottom: 5px;
	color: $color-secondary; }

.field-select {
	border: 1px solid $color-light-gray2;
	font-size: 14px;
	background: #fff;
	border-radius: 4px;
	padding: 0 10px;
	width: 100%;
	height: 32px;

	&.custom-ui {
		position: relative;
		box-sizing: border-box;
		min-width: 120px;
		cursor: pointer;

		&.active {
			.options-list {
				display: block; } }

		.selected-value {
			line-height: 30px;
			height: 30px;
			vertical-align: middle;
			white-space: nowrap;
			display: flex;
			justify-content: space-between;
			align-items: center;

			> span {
				display: block;
				padding-right: 10px; }
			.custom-ui-mask {
				position: absolute;
				z-index: 10;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0; }
			img {
				width: 24px;
				height: 24px; } }

		.options-list {
			display: none;
			position: absolute;
			border: 1px solid $color-light-gray2;
			border-radius: 4px;
			background: #fff;
			padding: 5px;
			left: 0;
			right: 0;
			top: 100%;
			z-index: 20;

			.option-item {
				padding: 5px;

				&:hover {
					background: #eee; }

				img {
					width: 26px;
					height: 26px; } } } } }

.field-checkbox {
	input {
		margin: 0 4px 0 0;
		vertical-align: -2px; }
	label {
		font-size: 11px;
		line-height: 1.55;
		color: $color-secondary; } }

.color-box {
	border: 1px solid #C4C4C4;
	box-sizing: border-box;
	border-radius: 4px;
	position: relative;
	background: #fff;
	width: 100%;

	.color-box-value {
		cursor: pointer;
		padding: 0 8px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 30px; }

	.color-box-swatch {
		border-radius: 3px;
		border: 1px solid #C4C4C4;
		width: 22px;
		height: 22px; }

	.color-box-picker {
		position: absolute;
		z-index: 10;
		border-radius: 4px;
		background: #fff;
		border: 1px solid #C4C4C4;
		padding: 8px;
		left: 0;
		top: 100%;
		margin-top: 5px;

		.sp-button {
			margin-top: 8px; } } }

.switcher {
	display: flex;
	color: $color-light-gray;
	font-weight: 500;
	font-size: 12px;
	line-height: 1.1;
	align-items: center;
	cursor: pointer;

	> div {
		width: 30px;
		border-radius: 8px;
		background: #D7D7D7;
		border: 1px solid #D7D7D7;
		justify-content: flex-start;
		display: flex;
		margin: 0 5px;
		height: 16px;

		> div {
			width: 16px;
			height: 16px;
			display: block;
			background: #fff;
			border-radius: 8px; }

		&.active {
			background: #6EE18E;
			border-color: #6EE18E;
			justify-content: flex-end; } } }

.sp-cols {
	display: flex;
	justify-content: space-between;

	&.sp-cols-2 {
		> div {
			width: 49%; } } }

.sp-button {
	background: $color-light-blue;
	box-sizing: border-box;
	text-decoration: none;
	display: inline-block;
	border-radius: 4px;
	line-height: 38px;
	border: 0 none;
	padding: 0 18px;
	height: 40px;
	color: #fff;
	cursor: pointer;
	font-size: 14px;
	text-align: center;
	outline: none;

	&:hover {
		background: #006E90; }

	&.bordered {
		background: #fff;
		color: $color-dark-gray;
		border: 1px solid $color-light-gray;

		&.disabled {
			color: rgba($color-dark-gray, 0.5); }

		&:hover {
			border-color: $color-secondary; }

		&.faded {
			border-color: $color-light-gray2;
			color: $color-light-gray2;

			&:hover {
				border-color: $color-gray;
				color: $color-gray; } }

		&.blue {
			border-color: $color-light-blue;
			color: $color-light-blue;

			&:hover {
				border-color: $color-dark-blue;
				color: $color-dark-blue; } } }

	&.disabled {
		cursor: default; }

	&.secondary {
		background: $color-light-gray;

		&:hover {
			background: $color-secondary;

			&.disabled {
				background: $color-light-gray; } } }

	&.bolder {
		font-weight: 700;
		text-transform: uppercase; }

	&.expanded {
		width: 100%; }

	&.padding-l {
		padding: 0 45px; }

	&.height-s {
		height: 30px;
		line-height: 28px; }

	&.flex {
		display: flex;
		align-items: center;
		justify-content: center;

		span {
			display: inline-block;
			text-align: center;
			line-height: 1.2; } }

	.l-icon {
		display: inline-block;
		vertical-align: 1px;

		svg {
			margin-right: 5px; } }

	.r-icon {
		display: inline-block;

		svg {
			margin-left: 5px; } } }

html, body {
	background: #fff; }

html, body, #root {
	letter-spacing: normal;
	position: relative;
	height: 100%; }

body, input, textarea, select, button {
	font-family: 'Open Sans', sans-serif; }

.app {
	display: flex;
	flex-direction: column;
	align-items: stretch;
	justify-content: stretch;
	position: relative;
	height: 100%;

	.header {
		position: relative;
		box-shadow: none;
		border-bottom: 1px solid $color-border;
		top: auto;
		left: auto;
		right: 0;
		z-index: 50;

		.site-content {
			max-width: none;
			padding: 20px 30px;
			display: flex;
			justify-content: space-between;

			&:after {
				content: none; } }

		.logo-wrapper {
			display: flex;

			.logo {
				float: none;
				margin-right: 24px; }

			.size-info {
				font-size: 16px;
				line-height: 1.25;
				color: $color-dark-gray;

				.size-selector {
					display: inline-block;
					position: relative;

					.trigger {
						color: $color-light-blue;
						cursor: pointer; } } } }

		.header-action {
			display: flex;
			align-items: center;

			.sp-button {
				margin-right: 10px;

				&:last-child {
					margin-right: 0; } }

			.cart-widget {
				position: relative;

				+ .sp-button {
					margin-left: 20px; }

				&.floated {
					.tooltip-popup {
						left: auto;
						right: -20px;
						margin-left: 0;

						&:before, &:after {
							left: auto;
							margin-left: 0;
							right: 40px; }
						&:after {
							margin-right: 2px; } } }

				.cart-trigger {
					margin: 0 0 0 5px;
					align-items: center;
					cursor: pointer;
					display: flex; }

				.cart-trigger-count {
					background: $color-light-blue;
					font-weight: 700;
					margin-left: 8px;
					width: 30px;
					height: 30px;
					border-radius: 50%;
					font-size: 13px;
					color: #fff;
					text-align: center;
					line-height: 30px; }

				.cart-row {
					display: flex;
					align-items: center;
					justify-content: space-between;
					font-size: 15px;
					padding: 20px;
					border-top: 1px solid #DCDEDE;

					.cart-row-left {
						display: flex;
						align-items: center; }

					.cart-row-right {
						display: flex;
						align-items: center; }

					.cart-row-qty {
						width: 55px; }

					.cart-row-size {
						font-size: 13px;

						.text-button {
							font-size: 12px;
							margin-left: 10px; } }

					.cart-row-remove {
						margin-left: 20px;
						cursor: pointer;
						width: 24px;
						text-align: center;

						svg {
							width: 8px;
							height: 8px; } }

					&.cart-row-totals {
						font-weight: 700;
						align-items: flex-start;

						.cart-row-right {
							display: block;
							padding-right: 28px;

							> div {
								display: flex;
								justify-content: space-between;
								align-items: center;

								> * {
									&:first-child {
										margin-right: 30px; } } }

							.text-button {
								font-weight: normal;
								font-size: 12px; } } } }

				.button-holder {
					text-align: center;
					margin-top: 20px; } } } }

	.inner {
		flex-grow: 1;
		display: flex;

		.side-collections {
			background: $bkg-color-light-gray;
			flex: 0 0 115px;
			box-sizing: border-box;
			border-right: 1px solid $color-border;
			text-align: center;
			display: flex;
			justify-content: space-between;
			flex-direction: column;

			.collections-list {
				@include reset-list;
				font-weight: 500;
				font-size: 11px;
				line-height: 1.1;
				color: $color-secondary;

				li {
					cursor: pointer;
					border-bottom: 1px solid $color-border;
					height: 100px;
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					text-transform: uppercase;
					background: #F3F5F4;

					figure {
						@include reset-figure;
						position: relative;
						margin-bottom: 6px;
						width: 46px;
						height: 46px;
						background: #fff;
						box-shadow: -2px 2px 4px rgba(0, 0, 0, 0.11);

						img {
							width: 46px;
							height: 46px; }

						.bubble {
							position: absolute;
							left: 50%;
							margin-left: 10px;
							top: -5px;
							background: $color-light-blue;
							border: 2px solid #fff;
							box-sizing: border-box;
							border-radius: 50%;
							padding-left: 1px;
							color: #fff;
							line-height: 20px;
							box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
							width: 24px;
							height: 24px;
							font-weight: 700;
							font-size: 10px; } }

					&:hover {
						background: #E3E3E3; }

					&.active {
						background: #fff;
						position: relative;

						&:after {
							background: #fff;
							position: absolute;
							content: '';
							left: 100%;
							width: 1px;
							top: 0;
							bottom: 0; } } } }

			.links-list {
				@include reset-list;
				padding: 0 0 10px;

				li {
					margin-bottom: 15px;
					cursor: pointer;
					font-size: 16px;
					line-height: 1.18;
					color: $color-gray;

					a {
						text-decoration: none;
						color: $color-gray; }

					&.blue {
						font-weight: 700;
						color: $color-light-blue;

						a {
							color: $color-light-blue; } } } } }

		.side-menu {
			width: 224px;
			background: #F6F8F8;
			text-transform: uppercase;
			padding: 40px 0 0;

			.section-title {
				margin: 0 10px 35px 45px; }

			ul {
				@include reset-list;
				line-height: 1.2;
				font-size: 14px;

				li {
					margin-bottom: 16px; }

				a {
					display: block;
					color: $color-dark-gray;
					padding: 12px 12px 12px 45px;
					text-decoration: none;

					&.active {
						color: #fff;
						background: $color-light-blue; } } } }

		.side-configurator {
			background: #fff;
			border-right: 1px solid $color-border;
			box-sizing: border-box;
			flex: 0 0 384px;
			padding: 18px;
			display: flex;
			flex-direction: column;

			.inner-box {
				background: #efefef;
				border-radius: 8px;
				padding: 12px 16px;
				margin-bottom: 16px;

				.sp-formfield {
					margin-bottom: 16px;

					&.mb-s {
						margin-bottom: 7px; } } }

			.photo-grid-holder {
				flex: 1;
				position: relative;

				.photo-grid-inner {
					position: absolute;
					left: 0;
					right: -16px;
					top: 0;
					bottom: 0;
					overflow: auto; } }

			.photo-grid {
				overflow: auto; } }

		main {
			display: flex;
			flex: 1 0 auto;
			width: calc(100% - 384px - 115px);

			.panel-screen {
				width: 100%;
				display: flex;
				flex-direction: column;
				background: #fff; }

			.panel-main {
				flex-grow: 1;
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: column;

				&.justify-top {
					justify-content: flex-start; } }

			.editor-configurator {
				background: #EFEFEF;
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 20px;

				.image-controls {
					display: flex;
					justify-content: space-between;
					align-items: center;

					.image-controls-inner {
						display: flex;
						> * {
							margin-right: 15px;
							&:last-child {
								margin-right: 0; } } }

					.panel-title {
						margin: 0 15px 0 0; }

					.switcher {
						margin-top: 11px; } }

				.buttons {
					display: flex;
					justify-content: space-between;

					.sp-button {
						margin-left: 6px; }

					.step-changer {
						.sp-button {
							width: 100px; } } } } } } }

.editor-frame {
	width: 564px;
	height: 564px;
	margin: 50px 0 16px;
	position: relative;
	border: 2px solid #717171;
	overflow: hidden;
	background: #ddd;
	cursor: move;

	.editor-border {
		position: absolute;
		box-shadow: 1px 1px 1px rgba(#000, 0.1);
		z-index: 5;

		&.editor-border-left {
			left: 10px;
			top: 10px;
			bottom: 10px;
			border-left: 1px dashed #fff; }
		&.editor-border-right {
			right: 10px;
			top: 10px;
			bottom: 10px;
			border-right: 1px dashed #fff; }
		&.editor-border-top {
			left: 10px;
			right: 10px;
			top: 10px;
			border-top: 1px dashed #fff;

			span {
				position: absolute;
				left: 0;
				bottom: -1px;
				font-size: 10px;
				color: #fff;
				text-shadow: 1px 1px 1px rgba(#000, 0.5); } }
		&.editor-border-bottom {
			left: 10px;
			right: 10px;
			bottom: 10px;
			border-bottom: 1px dashed #fff; } }

	.editor-image {
		position: absolute;
		left: 0;
		top: 0;
		width: 564px;
		height: 564px;

		&:after {
			content: '';
			position: absolute;
			left: -10000px;
			right: -10000px;
			top: -10000px;
			bottom: -10000px;
			z-index: 5; }

		&.no-mask {
			&:after {
				content: none; } }

		img {
			position: absolute;
			z-index: 4;
			left: 50%;
			top: 50%;

			&.wider {
				height: 100%;
				margin-top: -282px; }
			&.higher {
				width: 100%;
				margin-left: -282px; }
			&.static-image {
				position: static;
				width: 100%; } } }

	.badge-holder {
		position: absolute;
		display: flex;
		bottom: 50px;
		left: 50px;
		right: 50px;
		z-index: 6;

		&.to-edge {
			left: 0;
			right: 0; }
		&.position-left {
			justify-content: flex-start;
			.badge.has-logo {
				margin-left: 50px; } }
		&.position-center {
			justify-content: center; }
		&.position-right {
			justify-content: flex-end; } }

	.badge {
		cursor: default;
		min-height: 76px;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 316px;
		box-sizing: border-box;
		text-align: center;
		background: #C00000;
		color: #fff;
		font-weight: 700;
		flex-direction: column;
		position: relative;

		.input-holder {
			width: 100%;
			position: relative;

			.input-border {
				position: absolute;

				&.input-border-left {
					left: 12px;
					top: 0;
					bottom: 0;
					border-left: 1px dashed rgba(255, 255, 255, 0.6); }
				&.input-border-right {
					right: 12px;
					top: 0;
					bottom: 0;
					border-right: 1px dashed rgba(255, 255, 255, 0.6); }
				&.input-border-top {
					left: 12px;
					right: 12px;
					top: 0;
					border-top: 1px dashed rgba(255, 255, 255, 0.6); }
				&.input-border-bottom {
					left: 12px;
					right: 12px;
					bottom: 0;
					border-bottom: 1px dashed rgba(255, 255, 255, 0.6); } } }

		&.has-logo {
			input, textarea {
				padding-left: 42px; } }

		input, textarea {
			width: 100%;
			padding: 0;
			border: 0 none;
			text-align: center;
			height: 38px;
			outline: none;
			box-sizing: border-box;
			background: transparent;

			&.title-input {
				height: 28px; }
			&.is-hidden {
				position: fixed;
				left: -500px;
				top: -500px; }
			&.name-second {
				margin-top: -14px; }

			&::-webkit-input-placeholder {
				color: rgba(255, 255, 255, 0.75);
				font-style: italic;
				opacity: 1; }
			&::-moz-placeholder {
				color: rgba(255, 255, 255, 0.75);
				font-style: italic;
				opacity: 1; }
			&:-ms-input-placeholder {
				color: rgba(255, 255, 255, 0.75);
				font-style: italic;
				opacity: 1; }
			&:-moz-placeholder {
				color: rgba(255, 255, 255, 0.75);
				font-style: italic;
				opacity: 1; } }

		textarea {
			position: absolute;
			bottom: 100%;
			margin-bottom: 10000px;
			left: 0;
			right: 0; }

		.badge-logo {
			width: 100px;
			height: 100px;
			position: absolute;
			left: -50px;
			top: -12px; } } }

.editor-controls {
	display: flex;
	height: 80px;
	margin-bottom: 30px;
	background: #fff;
	border: 1px solid #E0E2E2;
	border-radius: 7px;
	align-items: center;
	justify-content: space-between;
	text-align: center;
	width: 564px;
	font-weight: 500;
	font-size: 12px;
	line-height: 1.42;
	color: $color-dark-gray;
	cursor: pointer;
	box-sizing: border-box;
	padding: 0 50px;

	figure {
		margin: 0;
		text-align: center;
		margin-bottom: 5px;
		line-height: 22px; } }

.gallery-holder {
	width: 884px;
	margin-top: 48px;
	max-width: 100%;

	&.mb-s {
		.modal.modal-upload.modal-static {
			margin-bottom: 20px; } } }

.info-banner {
	background: #FAFAFA;
	border-radius: 12px;
	display: flex;
	position: relative;
	align-items: center;
	justify-content: space-between;
	padding: 20px 30px;
	overflow: hidden;
	color: $color-dark-gray;

	+ .info-banner {
		margin-top: 20px; }

	figure {
		margin: 0 25px 0 0; }

	.new {
		background: $color-light-blue;
		color: #fff;
		position: absolute;
		text-transform: uppercase;
		font-size: 13px;
		font-weight: 600;
		padding: 15px 25px 5px;
		transform: rotate(45deg);
		right: -25px;
		top: -5px; }

	.info-banner-content {
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex: 1;

		&.centered {
			justify-content: center;
			text-align: center; }

		.info-banner-text {
			font-size: 15px; }

		.info-banner-title {
			font-size: 24px;
			font-weight: 700;
			text-transform: uppercase;

			&.smaller {
				font-size: 21px;
				text-transform: none; } } }

	.info-banner-buttons {
		font-weight: 500;
		font-size: 14px;
		color: $color-border;
		line-height: 1.2;

		.separator {
			margin: 0 10px;
			vertical-align: 1px; }

		.text-button {
			font-size: 16px; } } }

.gallery-info {
	margin: 0 0 20px;
	font-size: 15px;
	line-height: 1.2;
	color: $color-gray; }

.gray-bar-title {
	background: $bkg-color-light-gray;
	color: $color-dark-gray;
	border-radius: 12px;
	text-align: center;
	margin: 0 0 38px;
	font-size: 15px;
	padding: 22px;

	.main-title {
		font-weight: 600;
		margin-bottom: 8px;
		text-transform: uppercase;
		font-size: 21px; } }

.grid-head {
	display: flex;
	margin-bottom: 20px;
	justify-content: space-between;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 1.16;
	color: $color-gray; }

.text-button {
	cursor: pointer;
	color: $color-light-blue;
	text-decoration: none; }

.nowrap {
	white-space: nowrap; }

.photo-grid {
	margin: 0;

	&.flex {
		display: flex;
		flex-wrap: wrap;
		max-width: 100%;
		margin-bottom: 25px;

		.tile-wrapper {
			//width: 25%
			width: 221px;
			font-size: 13px;
			margin-bottom: 5px;

			//.tile
			///display: block

			.title {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				max-width: 80%;
				margin: 0 10%; }

			.link {
				color: $color-light-blue; }

			.button {
				margin-top: 5px;

				span {
					cursor: pointer;
					color: #fff;
					display: inline-block;
					background: $color-light-blue;
					width: 48px;
					line-height: 22px;
					font-size: 11px;
					border-radius: 2px; } } } }

	&.centered {
		.tile {
			margin-left: auto;
			margin-right: auto; } }

	&.solo {
		width: auto;
		.tile {
			display: block; } }

	&.size-s {
		padding-left: 10px;
		.tile {
			width: 173px;
			height: 175px;
			.photo {
				width: 151px;
				height: 151px;

				.editor-frame {
					transform: scale(0.266); }

				.loading-wheel {
					left: 0;
					top: 0;
					position: absolute;
					width: 151px;
					height: 151px;
					z-index: 50 !important;

					&.inline {
						z-index: auto !important; }

					.loading-wheel-inside {
						margin-top: -13px;
						margin-left: -13px;
						width: 26px;
						height: 26px;
						z-index: 51;

						img {
							left: 2px;
							top: 2px; } } } } } }

	&.duo {
		width: auto;
		padding-left: 0;
		text-align: left; }

	.tile {
		background-size: 100% 100%;
		.photo {
			cursor: pointer;
			position: relative;
			overflow: hidden;

			&.active {
				&:after {
					left: 0;
					right: 0;
					top: 0;
					bottom: 0;
					border: 4px solid $color-light-blue;
					content: '';
					position: absolute;
					z-index: 5; } }

			img {
				position: absolute;
				object-fit: cover;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%; }

			.editor-frame {
				border: 0;
				margin-top: 0;
				position: absolute;
				left: 50%;
				top: 50%;
				margin-top: -282px;
				margin-left: -282px;
				transform: scale(0.347);
				cursor: default;

				.editor-image {
					&:after {
						content: none; }

					img {
						position: absolute;
						object-fit: fill;
						z-index: 4;
						left: 50%;
						top: 50%;

						&.wider {
							height: 100%;
							width: auto;
							margin-top: -282px; }
						&.higher {
							width: 100%;
							height: auto;
							margin-left: -282px; } } }

				.badge {
					position: relative;

					&:after {
						content: '';
						position: absolute;
						left: 0;
						top: 0;
						right: 0;
						bottom: 0; }

					.input-holder {
						.input-border {
							border: 0; } }
					.badge-logo {
						width: 100px;
						height: 100px;
						left: -50px;
						top: -12px; } } }

			.overlay {
				position: absolute;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
				padding: 12px;
				background: rgba(0,0,0,0.5);
				display: none;

				.overlay-buttons {
					display: flex;
					justify-content: space-between;
					align-items: flex-start;
					font-weight: 600;
					font-size: 14px;
					line-height: 1.1;
					color: #fff;
					text-shadow: 0px 1.5px 1.5px rgba(0, 0, 0, 0.4);
					text-transform: uppercase;
					position: relative;
					z-index: 6;

					> div {
						cursor: pointer; }

					span {
						margin-left: 5px; }

					svg {
						path {
							fill: #fff; } } } }

			.approved-overlay {
				position: absolute;
				left: 0;
				top: 0;
				right: 0;
				bottom: 0;
				background: rgba(0, 0, 0, 0.7); }

			.approved-icon {
				position: absolute;
				left: 10px;
				bottom: 10px;

				img {
					position: static;
					width: 24px; } }

			&:hover {
				.overlay {
					display: block; } } } } }

.table-wrapper {
	width: 100%;
	box-sizing: border-box;
	padding: 40px 45px 0;

	.table {
		width: 100%;
		margin-bottom: 32px;
		border-collapse: collapse;

		tr {
			td, th {
				padding: 15px;
				text-align: left;
				color: $color-dark-gray;
				font-size: 14px;
				line-height: 1.21;

				&.cell-checkbox {
					width: 20px;
					text-align: center;

					input {
						margin: 0; } }

				&.cell-helper {
					width: 100px; }

				&.cell-action {
					text-align: right;
					width: 100px;

					.text-button {
						margin-left: 10px;

						&:first-child {
							margin-left: 0; } } } }
			th {
				font-weight: 600;
				font-size: 12px;
				text-transform: uppercase; }
			&:nth-child(2n+1) {
				td {
					background: $bkg-color-light-gray; } } } }

	.table-pagination {
		margin-bottom: 32px;
		border: 1px solid $color-gray;
		overflow: hidden;
		border-radius: 5px;
		display: inline-block;

		> * {
			width: 40px;
			line-height: 40px;
			display: inline-block;
			text-decoration: none;
			text-align: center;
			color: $color-light-blue;
			border-right: 1px solid $color-gray;
			border-: 1px solid $color-gray;
			font-size: 14px;

			&.active, &.active:hover {
				background: $color-light-blue;
				color: #fff; }

			&:last-child {
				border-right: 0 none; }

			&:hover {
				background: $bkg-color-light-gray; } } } }

.form-section {
	margin-bottom: 32px;

	&.form-section-cols {
		.formfield {
			&.label-aligned {
				label {
					vertical-align: top;
					padding-top: 15px; } }

			label {
				display: inline-block;
				font-weight: 600;
				text-transform: uppercase;
				width: 105px;
				margin: 0; }
			input {
				&[type=text], &[type=email], &[type=tel], &[type=password], &[type=number] {
					display: inline-block;
					width: 400px; } }
			select, .datepicker, .input-holder {
				display: inline-block;
				width: 400px;

				&.multiple {
					height: 200px;
					padding: 10px 15px; }

				.input-note {
					font-size: 13px;
					color: $color-light-gray;
					margin-top: 8px; } }

			.multi-checkbox {
				display: inline-block;
				width: 400px; }

			.info-element {
				font-style: normal;
				font-weight: normal;
				font-size: 14px;
				line-height: 18px; } } }

	.form-cols {
		&.cols-2 {
			display: flex;
			align-items: flex-start;
			justify-content: space-between;

			> * {
				width: 49%; } }

		-label {}
		margin-bottom: 18px;
		display: flex;
		text-transform: uppercase;
		align-items: center;
		justify-content: space-between;
		font-size: 14px;

		.field-checkbox {
			line-height: 20px;

			label {
				font-size: 14px;
				color: $color-dark-gray;
				font-weight: 700; } }

		input {
			margin-right: 10px;
			vertical-align: -1px; } }

	.formfield {
		margin-bottom: 10px;
		label {
			display: block;
			margin-bottom: 3px;
			font-weight: 500;
			font-size: 12px;
			line-height: 1.33;
			color: $color-dark-gray;

			&.bold {
				font-weight: 700; }
			&.mb {
				margin-bottom: 10px; } }

		input {
			&[type=text], &[type=email], &[type=tel], &[type=password], &[type=number], &[type=date] {
				width: 100%;
				border: 1px solid #DBDBDB;
				height: 46px;
				box-sizing: border-box;
				border-radius: 0;
				outline: none;
				padding: 0 15px; } }
		.form-error {
			color: $color-red;
			font-size: 12px;
			margin-top: 5px; }

		.action-input {
			position: relative;

			.text-button {
				position: absolute;
				font-size: 14px;
				line-height: 20px;
				margin-top: -10px;
				right: 15px;
				top: 50%; } }

		.field-select {
			border: 1px solid #DBDBDB;
			height: 46px;
			border-radius: 0;
			outline: none;
			padding: 0 15px; }

		.multi-checkbox {
			border: 1px solid #DBDBDB;
			overflow: auto;
			max-height: 150px;
			padding: 10px 10px 0;
			box-sizing: border-box;

			label {
				width: auto;
				padding: 0 !important; } }

		&.formfield-inline {
			display: flex;

			> * {
				margin-right: 20px; }
			label {
				width: auto; } } }

	.buttons-bar {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 40px;
		font-size: 15px;

		.group {
			> .sp-button {
				margin-left: 1px; } } } }

.cols {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;

	&.cols-2 {
		> * {
			width: 48%; } } }

.admin-action {
	display: flex;
	justify-content: space-between;
	align-items: center;

	.text-button {
		svg {
			margin-right: 15px; } }

	.admin-action-buttons {
		display: flex;
		align-items: center;

		> * {
			margin-left: 4px; } } }

.section-entity {
	margin: 35px 0 50px;
	font-size: 25px; }

.checkboxes-list {
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 35px;

	&.stacked {
		display: block; }

	> * {
		width: 250px;
		margin: 0 20px 10px 0; }

	.field-checkbox {
		input {
			vertical-align: -1px;
			margin-right: 10px; }
		label {
			font-size: 15px; } } }

.search-box {
	position: relative;
	width: 290px;

	input {
		background: #fff;
		border: 1px solid #DBDBDB;
		box-sizing: border-box;
		border-radius: 5px;
		width: 100%;
		height: 40px;
		padding: 0 40px 0 15px; }

	button {
		border: 0;
		padding: 0;
		position: absolute;
		border-radius: 5px;
		right: 1px;
		top: 1px;
		background: transparent;
		cursor: pointer;
		height: 38px;
		width: 38px; }

	&.date-filters {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 320px;
		input {
			width: 48%;
			padding-right: 0; } } }

.modal.modal-upload {
	border: 1px dashed #D0D0D0;
	padding: 115px 25px 145px;
	height: auto;
	width: 885px;
	max-width: 100%;

	&.modal-static {
		position: relative;
		left: auto;
		top: auto;
		margin: 0 0 50px; }

	&:hover {
		background: #FAFAFA; }

	img {
		margin-bottom: 15px;
		opacity: 0.65; }
	h3 {
		color: #434343;
		font-size: 26px; }
	.or {
		font-size: 16px;
		color: #434343; }

	.loading-overlay {
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		background: #fff;
		display: flex;
		align-items: flex-end;
		padding: 15px;
		justify-content: center;
		font-style: italic;
		background: rgba(255,255,255,0.75); } }

.upload-control {
	text-align: center;
	display: inline-block;
	font-size: 13px;
	position: relative;
	overflow: hidden;
	width: 100px;

	.upload-control-box {
		border: 1px solid #DBDBDB;
		flex-direction: column;
		justify-content: center;
		font-size: 10px;
		cursor: pointer;
		display: flex;
		height: 100px;
		color: $color-light-gray;
		margin-bottom: 3px; }

	figure {
		margin: 0;

		img {
			max-width: 40px;
			max-height: 40px; } }

	input {
		display: block;
		position: absolute;
		opacity: 0;
		transform: scale(10);
		right: 0;
		top: 50%; } }

.tooltip-popup {
	color: $color-dark-gray;
	position: absolute;
	top: 100%;
	left: 50%;
	width: 490px;
	margin-top: 15px;
	margin-left: -245px;
	background: #fff;
	border: 1px solid #ccc;
	box-sizing: border-box;
	box-shadow: 0px 10px 14px rgba(0, 0, 0, 0.25);
	border-radius: 15px;
	padding: 30px 25px;
	z-index: 100;

	&:before, &:after {
		content: '';
		position: absolute;
		left: 50%;
		bottom: 100%;
		width: 0;
		height: 0; }

	&:before {
		border-left: 12px solid transparent;
		border-right: 12px solid transparent;
		border-bottom: 12px solid #ccc;
		margin-left: -12px; }

	&:after {
		border-left: 10px solid transparent;
		border-right: 10px solid transparent;
		margin-left: 2px;
		margin-left: -10px;
		border-bottom: 10px solid #fff; }

	.tooltip-popup-title {
		margin-bottom: 12px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-weight: 600;
		font-size: 20px;

		svg {
			margin-right: 10px;
			vertical-align: -3px; }

		.tooltip-popup-close {
			cursor: pointer;

			svg {
				margin: 0; } } }

	.tooltip-popup-desc {
		margin-bottom: 35px;
		font-size: 16px;
		line-height: 1.3; } }

.tooltip-popup-size {
	article {
		background: #F8F8F8;
		border: 2px solid #F8F8F8;
		border-radius: 10px;
		cursor: pointer;
		height: 152px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 25px;
		font-size: 30px;

		&:hover {
			border-color: $color-light-gray; }

		&.active {
			border-color: $color-light-blue; }

		.size-main-info {
			display: flex;
			align-items: center; }

		figure {
			margin: 0 22px 0 0; }

		.size-type-value, .size-price-value {
			font-size: 30px;
			font-weight: 700;
			line-height: 38px;
			letter-spacing: -1px; }

		.size-info-line {
			color: $color-secondary;
			font-size: 14px; }

		.size-price-value {
			font-size: 38px; }

		&.size-8x8 {
			figure {
				img {
					transform: scale(0.8); } } } }

	.separator {
		margin: 14px 12px;
		background: $color-border;
		height: 1px; }

	.shipping-info {
		text-align: center;
		margin-top: 22px;
		color: $color-secondary;
		font-size: 14px;

		svg {
			margin-left: 7px; } } }

.full-panel {
	height: 100%;
	width: 100%;
	display: flex;
	color: $color-dark-gray; }

.large-title {
	font-weight: 700;
	font-size: 23px;
	line-height: 1.2;
	text-transform: uppercase;
	margin-bottom: 15px;

	&.mb-xl {
		margin-bottom: 55px; } }

.small-title {
	color: $color-secondary;
	margin-bottom: 12px;
	font-weight: 600;
	font-size: 15px;

	&.mb-m {
		margin-bottom: 30px; } }

.order-summary {
	width: 72%;
	box-sizing: border-box;
	padding: 25px;

	&.centered {
		margin: 0 auto; }

	.order-table {
		width: 100%;
		margin-bottom: 100px;

		thead {
			th {
				border-bottom: 1px solid #EAEAEA;
				padding: 0 0 7px;
				font-weight: 600;
				font-size: 12px; } }
		tbody {
			td {
				border-bottom: 1px solid #EAEAEA;
				padding: 9px 0;
				font-size: 13px; } }
		tfoot {
			td {
				font-weight: 600;
				padding: 9px 0 0;
				font-size: 13px;

				&.regular-text {
					font-weight: normal; } } }

		.width-s {
			width: 100px; }

		.row-remove {
			cursor: pointer;

			svg {
				width: 8px;
				height: 8px; } } }

	.photo-section {
		border-top: 1px solid $color-border;
		padding-top: 25px;

		.photo-section-title {
			display: flex;
			justify-content: space-between;
			margin-bottom: 25px;
			align-items: center;
			font-weight: 600;
			font-size: 13px;
			text-transform: uppercase; } } }

.checkout-form {
	background: $bkg-color-light-gray;
	box-sizing: border-box;
	padding: 25px;
	width: 28%;

	.form-group {
		border-bottom: 1px solid $color-border;
		padding-bottom: 15px;
		margin-bottom: 15px;

		&.form-group-code {
			display: flex;
			align-items: center;
			justify-content: space-between;

			span {
				color: $color-light-blue;
				font-size: 12px;
				font-weight: 700;
				width: 30%;

				&.clickable {
					width: auto;
					cursor: pointer; } }

			form {
				width: 65%;
				display: flex;
				align-items: center;
				justify-content: space-between;

				input {
					width: 65%;
					height: 38px;
					padding: 0 12px;
					box-sizing: border-box;
					border: 1px solid $color-border;
					border-radius: 3px; }
				button {
					padding: 0;
					width: 30%; } } } }

	.form-group-title {
		text-transform: uppercase;
		margin-bottom: 15px;
		display: flex;
		align-items: center;

		span {
			margin-left: 10px;
			font-size: 15px;
			font-weight: 700; } }

	.formfield {
		display: flex;
		justify-content: space-between;
		margin-bottom: 5px;
		align-items: center;

		label {
			width: 30%;
			text-transform: uppercase;
			font-size: 12px;
			font-weight: 600; }
		input[type=text], input[type=email], select, .field-select {
			width: 65%;
			height: 40px;
			padding: 0 12px;
			box-sizing: border-box;
			border: 1px solid $color-border;
			border-radius: 3px;

			&.error {
				border-color: $color-red; }

			.selected-value {
				line-height: 40px;
				height: 40px; } }

		&.formfield-cc2 {
			input[type=text] {
				&:nth-child(2) {
					width: 25%; }
				&:nth-child(4) {
					width: 25%; } }
			label {
				&:nth-child(3) {
					width: 15%;
					text-align: right; } } } }

	.create-account {
		padding-left: 35%;
		display: flex;

		.field-checkbox {
			margin-right: 10px;
			label {
				font-size: 12px;
				color: $color-dark-gray; } }
		.text-button {
			font-size: 12px;
			line-height: 28px;
			display: inline-block; } }

	.totals {
		overflow: hidden;
		font-size: 13px;
		font-weight: 600;
		margin: 0;

		dt {
			float: left;
			clear: both;
			margin-bottom: 7px; }
		dd {
			float: right; }
		.total-line {
			font-weight: 800;
			text-transform: uppercase; }
		.discount-line {
			color: $color-red; }
		.text-button {
			margin-right: 10px; } }

	.checkout-button {
		background: #6EE18E;
		display: inline-block;
		width: 100%;
		border: 0 none;
		margin-bottom: 12px;
		height: 62px;
		line-height: 62px;
		padding: 0;
		font-size: 20px;
		font-weight: 700;
		color: #fff;
		cursor: pointer;
		border-radius: 5px;
		text-align: center;
		text-decoration: none;
		text-transform: uppercase; }

	.payment-methods {
		margin: 0 0 15px;
		text-align: center; }

	.shipping-cta {
		text-align: center;
		text-transform: uppercase;
		color: $color-secondary;
		font-weight: 600; }
	.shipping-estimate {
		display: flex;
		color: $color-light-gray;
		font-size: 15px;
		align-items: center;
		justify-content: center;

		span {
			margin-right: 10px; } } }

.order-message {
	margin-bottom: 55px;
	font-size: 15px;

	.order-number {
		margin-bottom: 30px;
		font-weight: 600;
		font-size: 20px; }

	strong {
		display: block;
		margin-bottom: 10px; } }

.shipping-address {
	margin-bottom: 55px;
	font-size: 15px;

	strong {
		font-weight: 600; }
	a {
		color: $color-light-blue;
		font-weight: 600; } }

.modal-overlay {
	background: rgba(0,0,0,0.75);
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 180;
	display: flex;
	align-items: center;
	justify-content: center;

	&.on-top {
		z-index: 200; }

	.modal-content {
		width: 560px;
		height: 400px;
		position: relative;
		background: #fff;
		border-radius: 10px;
		text-align: center;
		left: auto;
		top: auto;
		margin: 0;
		padding: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 15px;
		padding: 0 20px;
		box-sizing: border-box;

		&.modal-content-upload {
			width: 1025px;
			height: 680px;
			border-radius: 15px;

			.modal-upload {
				background: #FAFAFA;
				margin: 0; } }

		&.height-l {
			height: 500px; } }

	.close-btn {
		cursor: pointer;
		position: absolute;
		right: 20px;
		top: 20px; }

	.module-inner {
		figure {
			margin: 0 auto 15px; }
		.module-icon {
			background: $color-light-blue;
			width: 74px;
			line-height: 74px;
			border-radius: 50%; }
		.headline {
			margin: 0 0 15px;
			font-size: 20px;
			font-weight: 600;
			&.mb-0 {
				margin-bottom: 0; } }
		.image-preview {
			margin: 0;
			img {
				max-height: 100px;
				max-width: 320px; } }
		.below-headline {
			margin: 15px 25px;
			color: $color-secondary;
			a {
				color: $color-light-blue; }
			&.mt-0 {
				margin-top: 0; }
			&.mb-3 {
				margin-bottom: 45px; } }
		.buttons {
			> .sp-button {
				margin: 0 5px;

				&.w5 {
					width: 130px; } } }
		.note {
			margin: 15px 0 0;
			color: #CDCDCD; }
		textarea {
			width: 480px;
			height: 250px;
			display: block;
			box-sizing: border-box;
			font-size: 16px;
			margin-bottom: 10px; } } }

.upload-cta {
	background: url(../img/frame-3.png) left top / 100% 100% no-repeat;
	width: 173px;
	height: 175px;
	margin: 0 auto;
	cursor: pointer;
	position: relative;

	&:before {
		position: absolute;
		left: 4px;
		right: 18px;
		top: 4px;
		bottom: 18px;
		background: $bkg-color-light-gray;
		border: 1px dashed $color-light-gray;
		content: ''; }

	.upload-cta-plus {
		position: absolute;
		left: 4px;
		right: 18px;
		top: 4px;
		bottom: 18px;

		&:before, &:after {
			content: "";
			position: absolute;
			left: 50%;
			top: 50%;
			width: 70px;
			height: 10px;
			margin-left: -35px;
			margin-top: -5px;
			background: $color-light-gray2; }

		&:after {
			transform: rotate(90deg); } } }

.uploader-holder {
	position: relative;

	.uploader-mask {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		cursor: pointer;
		z-index: 150; } }

.braintree-placeholder {
	margin: -15px 0 0; }
.braintree-sheet__container {
	margin-bottom: 0; }
.braintree-sheet {
	border: 0 none;
	background: none;
	.braintree-sheet__header {
		display: none; }
	.braintree-sheet__content--form {
		padding: 0;
		.braintree-form__field-group {
			padding-left: 0; } }
	.braintree-form-number, .braintree-form-expiration {
    	background: #fff; }
	.braintree-form__label {
		text-transform: uppercase;
		font-size: 12px !important;
		font-weight: 600 !important; } }

.loading-wheel {
	&.inline {
		position: static !important;
		background: none;
		display: inline-block;

		.loading-wheel-inside {
			border: 1px solid $color-light-gray2;
			position: static !important;
			box-shadow: none;
			padding: 2px;
			margin: 0;

			img {
				width: 26px;
				height: auto; } } } }

.loading-wheel {
	&.inline {
		.loading-bundle {
			width: 100px;
			position: relative;
			margin-left: -50px; }
		&.large {
			.loading-bundle {
				width: 280px;
				margin-left: -140px; } } }

	.loading-bundle {
		width: 260px;
		height: 6px;
		background: #e1e4e8;
		border-radius: 3px;
		position: absolute;
		left: 50%;
		top: 50%;
		margin-left: -130px;
		margin-top: -3px;
		overflow: hidden;

		.loading-bundle-inner {
			position: absolute;
			border-radius: 3px;
			left: -100%;
			top: 0;
			height: 100%;
			background: linear-gradient(90deg,#ffd33d,#ea4aaa 17%,#b34bff 34%,#01feff 51%,#ffd33d 68%,#ea4aaa 85%,#b34bff);
			animation: progress-animation 2s linear infinite;
			width: 100%;

			@keyframes progress-animation {
	  			0% {
	    			left: -100%; }
	  			100% {
	    			left: 0; } } } } }

.centered-button-holder {
	padding: 10px 0 0;
	text-align: center;

	&.spaced {
		display: flex;
		justify-content: space-between; } }

// @media (max-width: 1499px)
@media (max-width: 199px) {
	.app {
		.inner {
			main {
				.editor-configurator {
					.image-controls {
						display: block;
						.panel-title {
							margin-bottom: 10px; } } } } } } }

// @media (max-width: 1279px)
@media (max-width: 199px) {
	.app .inner .side-configurator {
		flex: 0 0 200px; }
	.sp-cols.fw-mob {
		display: block;
		&.sp-cols-2 {
			> div {
				width: 100%; } } }
	.title-line {
		display: block; }
	.gallery-holder {
		width: 600px; }
	.gallery-info {
		+ .photo-grid {
			.tile-wrapper {
				transform: scale(0.8);
				margin: -25px -25px 0 0; } } }
	.modal-overlay .modal-content.modal-content-upload {
		width: 920px;
		height: 600px; }
	.app .inner main .editor-configurator {
		.buttons {
			> div:first-child, .secondary {
				display: none; } } } }
